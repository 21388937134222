export function formatDate(date) {
  date = new Date(date);

  if (isNaN(date.valueOf())) {
    // No shirt - no service!
    return '';
  }

  const month = date.toLocaleDateString('en', { month: 'long' });
  const day = date.toLocaleDateString('en', { day: 'numeric' });
  const year = date.toLocaleDateString('en', { year: 'numeric' });
  return `${month} ${day}, ${year}`;
}

export default formatDate;
