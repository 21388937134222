import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Thumb from '../Thumb/Thumb';

const Team = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        allContentfulTeam {
          edges {
            node {
              teamMembers {
                bio {
                  raw
                }
                slug
                role
                name
                image {
                  file {
                    url
                  }
                  title
                }
              }
            }
          }
        }
      }
    `);

  const posts = data?.allContentfulTeam?.edges[0]?.node?.teamMembers;
  console.dir(data)
  return (
    <div className='thumbs'>
      {posts.map((node, key) => 
        <Thumb 
          category='team'
          slug={node.slug} 
          image={node.image} 
          title={node.name}
          sub={node.role}
          key={key}
          align='center'
          classNames='sq'
          />
      )}
    </div>    
  )
}

export default Team;


