import React from "react";
import Page from "../components/Page/Page";
import Team from '../components/Team/Team';
import Coaches from '../components/Coaches/Coaches';
import News from '../components/News/News';
import { graphql } from "gatsby";
import { Widget } from '@typeform/embed-react'
import '../scss/main.scss';

const Pages = ({data}) => {
    console.log(`data.contentfulPages.slug ${data.contentfulPages.slug}`)
    console.dir(data);
    const { slug, widgetId } = data.contentfulPages;


    return (
      <>
        <Page {...data.contentfulPages}>
          {slug ==='team' && <Team/>}
          {slug ==='coaches' && <Coaches/>}
          {slug ==='news' && <News/>}
          {widgetId && <Widget id={widgetId} className="page-form" />}
        </Page>
      </>
    )
}

export default Pages

export const pagesQuery = graphql`
  query PageBySlug($slug: String!) {
    contentfulPages(slug: { eq: $slug }) {
      slug
      title
      heroDescription {
          raw
      }
      metaTitle
      metaDesc
      metaImage {
          file {
          url
          }
      }
      image {
          file {
          url
          }
      }
      headline
      description {
          raw
      }
      buttons {
        link {
          link
        }
        target
        title
      }
      widgetId
      thumbVideo {
        title
        thumb {
          file {
            url
          }
        }
        desc {
          raw
        }
        file {
          title
          file {
            url
          }
          description
        }
      }
    }
  }
`
