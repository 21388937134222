import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Thumb from '../Thumb/Thumb';
import formatDate from '../../tools/formatDate';

const News = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        allContentfulNews(sort: { fields: [publishDate], order: DESC }) {
            edges {
              node {
                description {
                  raw
                }
                image {
                  file {
                    url
                  }
                  title
                }
                title
                slug
                excerpt
                publishDate
              }
            }
          }
      }
    `);

  const posts = data.allContentfulNews.edges;
  console.log('news')
  console.dir(posts);

  return (
    <div className='thumbs'>
      {posts.map((node, key) => 
        <Thumb 
          category='news'
          slug={node.node.slug} 
          image={node.node.image} 
          title={node.node.title}
          sub={formatDate(node.node.publishDate)}
          key={key}
          align='center'
          classNames=''
          />
      )}
    </div>    
  )
}

export default News;


