import React, { useEffect, useState } from "react";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Seo from '../Seo/Seo';
import Image from '../Image/Image';
import { renderRichText } from "gatsby-source-contentful/rich-text";

interface Props {
  title:string;
  headline:string;
  heroDescription:any;
  description:any;
  image:{
    title:string;
    file: {
      url:string;
    };
  };
  metaTitle:string;
  metaDesc:string;
  metaImage:string;
  articleTitle:string;
  slug:string;
  buttons:any;
  thumbVideo:any;
  children: React.ReactNode;
}
const Page: React.FC<Props> = (props) => {

  const { slug, title, headline, heroDescription, image, description, buttons, thumbVideo } = props;

  const [loaded, setLoaded] = useState('');

  useEffect(() => {
    if(!image) {
      setLoaded('loaded');
    }    
  }, [props]);

  useEffect(() => {
    console.log(`loaded ${loaded}`)
  }, [loaded]);

  console.dir(description)
  const pageImageLoaded = () => setLoaded('loaded');

  return (
    <>
    <Seo data={props}/>
    <Header />
    <main className={`page ${slug} ${loaded}`}>
      {title && title !== 'Home' && <h1>{title}</h1> }
      <section className="hero">
        <div className="info">
          {headline && <h2>{headline}</h2>}
          {heroDescription && <div className="page-desc">
            {renderRichText(heroDescription)} 
          </div>}
          {buttons && <div className="btns">
            {buttons.map((btn, key) => (
              <a href={btn.link.link} target={btn.target} className='round-btn green' key={key}>
                {btn.title}
              </a>
            ))}
          </div>}
        </div>{/* info */} 
        {image && <div className="img">
          <Image {...image} onLoad={() => setLoaded('loaded')}/>
        </div>}
      </section>{/* hero */}      
      {description && <section className="page-desc">
          {renderRichText(description)}
        </section>}
      {props.children}
    </main>
    <Footer/>
    </>
  )
}

export default Page
