import React from 'react';
import Image from '../Image/Image';

interface Props {
  category:string;
  slug:string;
  title:string;
  excerpt?:string;
  sub?:string;
  align?:string;
  image:{
    title:string;
    file: {
      url:string;
    };
  };
  classNames?:string;
}

const Thumb: React.FC<Props> = (props) => {
  console.dir(props);
  const { align, category, slug, image, title, excerpt, sub, classNames } = props;
  return (
    <a className={`thumb ${align} ${classNames}`} href={`/${category}/${slug}`} target='_self' key={slug}>
        {image && <div className="img">
          <Image {...image}/>
        </div>}
        <h4>{title}</h4>
        {excerpt && <p>{excerpt}</p>}
        {sub && <p>{sub}</p>}
    </a>
  )
}

export default Thumb;