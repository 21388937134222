import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Thumb from '../Thumb/Thumb';

const Coaches = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        allContentfulCoaches {
          edges {
            node {
              coach {
                bio {
                  raw
                }
                slug
                role
                name
                image {
                  file {
                    url
                  }
                  title
                }
              }
            }
          }
        }
      }
    `);

  const posts = data.allContentfulCoaches.edges[0].node.coach;

  return (
    <div className='thumbs'>
      {posts.length > 1 && posts.map((node, key) => 
        <Thumb 
          category='coaches'
          slug={node.slug} 
          image={node.image} 
          title={node.name}
          sub={node.role}
          key={key}
          align='center'
          classNames='sq'
          />
      )}
    </div>    
  )
}

export default Coaches;


